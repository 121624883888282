import { defineMessage } from 'react-intl';

import type { ValidationError } from '../../../services/forms';

export const ERROR_MESSAGES: Record<string, ValidationError> = {
  required: {
    message: defineMessage({
      defaultMessage:
        'Accept the Terms and Conditions and Privacy Policy to continue',
      description:
        "validation error shown in the signup form if user doesn't accept terms & conditions and privacy policy",
    }),
    type: 'error',
  },
} as const;
