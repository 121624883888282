import { Checkbox, type CheckboxProps } from '@sumup-oss/circuit-ui';
import { type FunctionComponent, type Ref, forwardRef } from 'react';
import { useIntl } from 'react-intl';

import type { Country } from '../../../services/i18n';

import { PrivacyLink } from './PrivacyLink';
import { TermsLink } from './TermsLink';

export type TermsCheckboxProps = {
  country: Country;
  previousCountry?: Country;
} & Partial<CheckboxProps>;

const TermsCheckbox: FunctionComponent<TermsCheckboxProps> = forwardRef(
  ({ country, previousCountry, ...props }, ref) => {
    const intl = useIntl();

    // with object spread this didn't work and the checkbox failed to register
    // with the react hook form using this component
    const checked =
      !!previousCountry && previousCountry !== country ? false : undefined;

    return (
      <Checkbox
        ref={ref as Ref<HTMLInputElement>}
        defaultChecked={false}
        value="true"
        checked={checked}
        {...props}
        label={
          intl.formatMessage(
            {
              defaultMessage:
                'By ticking this box you accept the {termsAndConditions} and acknowledge that you have read and understood the {privacyPolicy}',
              description:
                'prompt for agreement to terms and conditions and privacy policy',
            },
            {
              termsAndConditions: <TermsLink country={country} />,
              privacyPolicy: <PrivacyLink country={country} />,
            },
          ) as unknown as string
        }
      />
    );
  },
);

TermsCheckbox.displayName = 'TermsCheckbox';

export default TermsCheckbox;
